/*
 *   File : returns.js
 *   Author : https://evoqins.com
 *   Description : Manage returns
 *   Version : 1.0.0
*/

//Packages
import React, { Fragment, useEffect, useState } from 'react';

// Components
import { CustomTitle } from '../../Components/Title';
import { CustomTextInput1 } from '../../Components/FormElements';
import { Icon } from '../../Components/Icon';
import { TextButton } from '../../Components/Buttons';
import { CustomTabBar } from '../../Components/Tab';

// Services
import APIService from '../../Services/api-service';
import { toast } from 'react-toastify';


const INVESTMENT_TYPE = [
    {
        label: "Conservative",
        id: 1,
    },
    {
        label: "Balanced",
        id: 2,

    },
    {
        label: "Moderate",
        id: 3
    },
    {
        label: "Aggressive",
        id: 4
    },
]

const GOAL_YEARS = [
    {
        label: "1 year",
        id: 1
    },
    {
        label: "2 year",
        id: 2
    },
    {
        label: "3 year",
        id: 3
    },
    {
        label: "4 year",
        id: 4
    },
    {
        label: "5 year",
        id: 5
    },
    {
        label: "6 year",
        id: 6
    }
]


const ReturnsList = () => {
    const [returnType, setReturnType] = useState(1);
    const [returnsData, setReturnsData] = useState({});
    const [homeEditEnabled, setHomeEditEnabled] = useState(null);
    const [fdEditEnabled, setFdEditEnabled] = useState(null);
    const [goalEditEnabled, setGoalEditEnabled] = useState(null);
    const [investmentType, setInvestmentType] = useState(INVESTMENT_TYPE[0].id);
    const [year, setYear] = useState(GOAL_YEARS[0].id);
    const [pageLoader, setPageLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(null);

    useEffect(() => {
        _getReturns();
    }, []);

    function _handleReturnType(type) {
        setReturnType(type)
    }

    function _handleReturnsData(input_value, key, cagr, return_type) {
        const returns_data = { ...returnsData }
        returns_data[`${key}`][`${cagr}`][`${return_type}`] = input_value
        setReturnsData(returns_data)
    }

    function _handleGoalReturnsData(input_value, key, investment_type, year_value, return_type,) {
        const returns_data = { ...returnsData }
        returns_data[`${key}`][`${investment_type}`][`${year_value}`][`${return_type}`] = input_value
        setReturnsData(returns_data)
    }

    // API - get returns
    const _getReturns = () => {
        const url = "/returns/get";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setReturnsData(response.data);
            }
            setPageLoader(false);
        });
    }

    // API - Update home returns
    const _updateHomePageReturns = (cagr, index) => {
        setApiLoader(index);

        const url = "/returns/update";

        const request = {
            "year": returnsData.home[`${cagr}`].year ? returnsData.home[`${cagr}`].year : 0,
            "fd": returnsData.home[`${cagr}`].fd ? returnsData.home[`${cagr}`].fd : 0,
            "nifty": returnsData.home[`${cagr}`].nifty ? returnsData.home[`${cagr}`].nifty : 0,
            "finozone": returnsData.home[`${cagr}`].finozone ? returnsData.home[`${cagr}`].finozone : 0
        }
        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("Home returns updated successfully", {
                    type: "success"
                });
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(null);
            setHomeEditEnabled(null);
        });
    }

    // API - Update FD returns
    const _updateFdReturns = (cagr, index) => {
        setApiLoader(index);

        const url = "/returns/fd";

        const request = {
            "year": returnsData.fd[`${cagr}`].year ? returnsData.fd[`${cagr}`].year : 0,
            "fd": returnsData.fd[`${cagr}`].fd ? returnsData.fd[`${cagr}`].fd : 0,
            "nifty": returnsData.fd[`${cagr}`].nifty ? returnsData.fd[`${cagr}`].nifty : 0,
            "finozone": returnsData.fd[`${cagr}`].finozone ? returnsData.fd[`${cagr}`].finozone : 0,
            "annual_returns": returnsData.fd[`${cagr}`].annual_returns ? returnsData.fd[`${cagr}`].annual_returns : 0
        }
        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("FD returns updated successfully", {
                    type: "success"
                });
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(null);
            setFdEditEnabled(null);
        });
    }

    // API - Update Goal returns
    const _updateGoalReturns = (cagr, index) => {
        setApiLoader(index);

        const url = "/returns/goal";

        const request = {
            "investment_style": investmentType,
            "year": year,
            returns: {
                "fd": returnsData.goal[`${investmentType}`][`${year}`].fd ? returnsData.goal[`${investmentType}`][`${year}`].fd : 0,
                "nifty": returnsData.goal[`${investmentType}`][`${year}`].nifty ? returnsData.goal[`${investmentType}`][`${year}`].nifty : 0,
                "finozone": returnsData.goal[`${investmentType}`][`${year}`].finozone ? returnsData.goal[`${investmentType}`][`${year}`].finozone : 0,
            },
            "annual_returns": returnsData.goal[`${investmentType}`][`${year}`].annual_returns ? returnsData.goal[`${investmentType}`][`${year}`].annual_returns : 0
        }
        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("Goal returns updated successfully", {
                    type: "success"
                });
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(null);
            setGoalEditEnabled(null);
        });
    }
    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Manage Returns" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-white border-radius-32px padding-40px-tb padding-32px-lr">
                            {
                                pageLoader === true ?
                                    <div className="h-80vh d-flex align-items-center justify-content-center">
                                        <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                                            alt={"loader"}
                                            width={60}
                                            height={60}
                                        />
                                    </div>
                                    :
                                    <Fragment>
                                        {/* Home page returns */}
                                        <div className="border-1px border-color-mint-gray border-radius-12px p-3 mb-3" >
                                            <div className='d-flex justify-content-between align-items-center py-2 cursor-pointer' onClick={() => {
                                                if (returnType !== 1) {
                                                    _handleReturnType(1);
                                                } else {
                                                    _handleReturnType(null);
                                                }
                                            }}>
                                                <h6 className="color-dark-charcoal e-font-14 e-montserrat-bold e-line-height-24 mb-0">
                                                    Home page returns
                                                </h6>
                                                <Icon icon="arrow-down"
                                                    size={16}
                                                    color="#000000" />
                                            </div>
                                            {
                                                returnType === 1 &&
                                                <Fragment>
                                                    {
                                                        Object.entries(returnsData.home).map(([year, returnValue], index) => {
                                                            return (
                                                                <div className='row pt-3 align-items-center'>
                                                                    <div className='col-md-2'>
                                                                        <CustomTextInput1
                                                                            label="Year/CAGR%"
                                                                            value={year}
                                                                            isDisabled={true}
                                                                            placeHolder="Year/CAGR%"
                                                                        />
                                                                    </div>
                                                                    <div className='col-md-2'>
                                                                        <CustomTextInput1 label="FD%"
                                                                            value={returnValue.fd}
                                                                            placeHolder="FD%"
                                                                            isDisabled={homeEditEnabled !== index ? true : false}
                                                                            handleChange={(input_value) => _handleReturnsData(input_value, "home", year, "fd")} />
                                                                    </div>
                                                                    <div className='col-md-2'>
                                                                        <CustomTextInput1 label="Nifty%"
                                                                            value={returnValue.nifty}
                                                                            placeHolder="Nifty%"
                                                                            isDisabled={homeEditEnabled !== index ? true : false}
                                                                            handleChange={(input_value) => _handleReturnsData(input_value, "home", year, "nifty")} />
                                                                    </div>
                                                                    <div className='col-md-2'>
                                                                        <CustomTextInput1 label="MFNXT%"
                                                                            value={returnValue.finozone}
                                                                            placeHolder="MFNXT%"
                                                                            isDisabled={homeEditEnabled !== index ? true : false}
                                                                            handleChange={(input_value) => _handleReturnsData(input_value, "home", year, "finozone")} />
                                                                    </div>
                                                                    <div className='col pt-3 mt-1'>
                                                                        <TextButton name={index === homeEditEnabled ?
                                                                            index === apiLoader ? "Saving..." : "Save" :
                                                                            "Edit"
                                                                        }
                                                                            hide_arrow={true}
                                                                            onPress={() => {
                                                                                if (index === homeEditEnabled) {
                                                                                    _updateHomePageReturns(year, index)
                                                                                } else {
                                                                                    setHomeEditEnabled(index);
                                                                                }
                                                                            }} />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                            }

                                        </div>


                                        {/* FD returns */}
                                        <div className="border-1px border-color-mint-gray border-radius-12px p-3 mb-3" >
                                            <div className='d-flex justify-content-between align-items-center py-2 cursor-pointer' onClick={() => {
                                                if (returnType !== 2) {
                                                    _handleReturnType(2);
                                                } else {
                                                    _handleReturnType(null);
                                                }
                                            }}>
                                                <h6 className="color-dark-charcoal e-font-14 e-montserrat-bold e-line-height-24 mb-0">
                                                    FD returns
                                                </h6>
                                                <Icon icon="arrow-down"
                                                    size={16}
                                                    color="#000000" />
                                            </div>
                                            {
                                                returnType === 2 &&
                                                <Fragment>
                                                    {
                                                        Object.entries(returnsData.fd).map(([year, returnValue], index) => {
                                                            return (
                                                                <div className='row pt-3 align-items-center'>
                                                                    <div className='col-md-2'>
                                                                        <CustomTextInput1
                                                                            label="Year/CAGR%"
                                                                            value={year}
                                                                            isDisabled={true}
                                                                            placeHolder="Year/CAGR%"
                                                                        />
                                                                    </div>
                                                                    <div className='col-md-2'>
                                                                        <CustomTextInput1 label="FD%"
                                                                            value={returnValue.fd}
                                                                            placeHolder="FD%"
                                                                            isDisabled={fdEditEnabled !== index ? true : false}
                                                                            handleChange={(input_value) => _handleReturnsData(input_value, "fd", year, "fd")} />
                                                                    </div>
                                                                    <div className='col-md-2'>
                                                                        <CustomTextInput1 label="Nifty%"
                                                                            value={returnValue.nifty}
                                                                            placeHolder="Nifty%"
                                                                            isDisabled={fdEditEnabled !== index ? true : false}
                                                                            handleChange={(input_value) => _handleReturnsData(input_value, "fd", year, "nifty")} />
                                                                    </div>
                                                                    <div className='col-md-2'>
                                                                        <CustomTextInput1 label="MFNXT%"
                                                                            value={returnValue.finozone}
                                                                            placeHolder="MFNXT%"
                                                                            isDisabled={fdEditEnabled !== index ? true : false}
                                                                            handleChange={(input_value) => _handleReturnsData(input_value, "fd", year, "finozone")} />
                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        <CustomTextInput1 label="Annual returns"
                                                                            value={returnValue.annual_returns}
                                                                            placeHolder="Annual returns"
                                                                            isDisabled={fdEditEnabled !== index ? true : false}
                                                                            handleChange={(input_value) => _handleReturnsData(input_value, "fd", year, "annual_returns")} />
                                                                    </div>
                                                                    <div className='col pt-3 mt-1'>
                                                                        <TextButton name={fdEditEnabled === index ?
                                                                            index === apiLoader ? "Saving..." : "Save" :
                                                                            "Edit"} hide_arrow={true}
                                                                            onPress={() => {
                                                                                if (fdEditEnabled === index) {
                                                                                    _updateFdReturns(year, index)
                                                                                } else {
                                                                                    setFdEditEnabled(index);
                                                                                }
                                                                            }} />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                            }

                                        </div>


                                        {/* Goal returns */}
                                        <div className="border-1px border-color-mint-gray border-radius-12px p-3 mb-3" >
                                            <div className='d-flex justify-content-between align-items-center py-2 cursor-pointer' onClick={() => {
                                                if (returnType !== 3) {
                                                    _handleReturnType(3);
                                                } else {
                                                    _handleReturnType(null);
                                                }
                                            }}>
                                                <h6 className="color-dark-charcoal e-font-14 e-montserrat-bold e-line-height-24 mb-0">
                                                    Goal returns
                                                </h6>
                                                <Icon icon="arrow-down"
                                                    size={16}
                                                    color="#000000" />
                                            </div>

                                            {
                                                returnType === 3 &&
                                                <Fragment>
                                                    <CustomTabBar data={INVESTMENT_TYPE}
                                                        selectedTab={investmentType}
                                                        onSelectTab={(tab_index) => setInvestmentType(tab_index)} />
                                                    <CustomTabBar data={GOAL_YEARS}
                                                        selectedTab={year}
                                                        onSelectTab={(tab_index) => setYear(tab_index)} />
                                                    <div className='row pt-3 align-items-center'>
                                                        <div className='col-md-2'>
                                                            <CustomTextInput1 label="FD%"
                                                                value={returnsData.goal[`${investmentType}`][`${year}`].fd}
                                                                placeHolder="FD%"
                                                                isDisabled={goalEditEnabled !== 1 ? true : false}
                                                                handleChange={(input_value) => _handleGoalReturnsData(input_value, "goal", investmentType, year, "fd")} />
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <CustomTextInput1 label="Nifty%"
                                                                value={returnsData.goal[`${investmentType}`][`${year}`].nifty}
                                                                placeHolder="Nifty%"
                                                                isDisabled={goalEditEnabled !== 1 ? true : false}
                                                                handleChange={(input_value) => _handleGoalReturnsData(input_value, "goal", investmentType, year, "nifty")} />
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <CustomTextInput1 label="MFNXT%"
                                                                value={returnsData.goal[`${investmentType}`][`${year}`].finozone}
                                                                placeHolder="MFNXT%"
                                                                isDisabled={goalEditEnabled !== 1 ? true : false}
                                                                handleChange={(input_value) => _handleGoalReturnsData(input_value, "goal", investmentType, year, "finozone")} />
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <CustomTextInput1 label="Yearly returns%"
                                                                value={returnsData.goal[`${investmentType}`][`${year}`].annual_returns}
                                                                placeHolder="Yearly returns%"
                                                                type="text"
                                                                isDisabled={goalEditEnabled !== 1 ? true : false}
                                                                handleChange={(input_value) => _handleGoalReturnsData(input_value, "goal", investmentType, year, "annual_returns")} />
                                                        </div>
                                                        <div className='col pt-3 mt-1'>
                                                            <TextButton name={goalEditEnabled === 1 ?
                                                                1 === apiLoader ? "Saving..." : "Save" :
                                                                "Edit"
                                                            } hide_arrow={true}
                                                                onPress={() => {
                                                                    if (goalEditEnabled === 1) {
                                                                        _updateGoalReturns()
                                                                    } else {
                                                                        setGoalEditEnabled(1);
                                                                    }
                                                                }} />
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            }

                                        </div>
                                    </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ReturnsList